
<template>
  <div
    v-if="isReScheduling"
    class="re-scheduling-mask"
  >
    <spinner
      title="Rescheduling ..."
    />
  </div>
  <div
    v-else
    class="current-simulation"
  >
    <h2 class="header">
      Current {{ isOt ? 'Scheduling' : 'Allocation' }} Plan
    </h2>
    <schedule-button
      v-if="planned"
      class="simulate-btn"
      @click="$router.push({ name: 'DemoOTSimulationSetting' })"
    >
      Resimulation
    </schedule-button>
    <div
      v-if="planned"
      class="simulation-content"
    >
      <div
        v-if="isOt"
        class="plan-KPI KPI"
      >
        <h3 class="KPI__title">
          OT Room Scheduling
        </h3>
        <div class="KPI__info">
          <span class="KPI__info--item">
            Scheduling Time Range: 2021/3/1-2021/3/31
          </span>
          <span class="KPI__info--item">
            Total Surgeroy: 128
          </span>
          <span class="KPI__info--item">
            Utilization Rate: 90%
          </span>
          <span class="KPI__info--item">
            Total turnover time: 3.27h
          </span>
        </div>
      </div>
      <plan-job
        :re-scheduled="reScheduled"
        @reSchedule="reSchedule"
      />
      <plan-gantt
        v-show="isOt"
        :re-scheduled="reScheduled"
      />
    </div>
    <div
      v-else
      class="empty-block"
    >
      <img
        class="empty-block__icon"
        src="@/schedule/icons/empty.svg"
      >
      <span class="empty-block__text">No Scheduling Plan</span>
      <schedule-button
        class="empty-block__btn"
        @click="$router.push({ name: 'DemoOTSimulationSetting' })"
      >
        Start Simulation Planning
      </schedule-button>
    </div>
  </div>
</template>

<script>
import PlanJob from './components/PlanJob'
import PlanGantt from './components/PlanGantt'

export default {
  name: 'CurrentSimulation',
  components: {
    PlanJob,
    PlanGantt
  },
  props: {
    isOt: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isReScheduling: false,
      reScheduled: false
    }
  },
  computed: {
    planned () {
      return this.$route.query.planned || this.$route.query.planned === 'true'
    }
  },
  methods: {
    reSchedule () {
      this.isReScheduling = true
      window.setTimeout(() => {
        this.isReScheduling = false
        this.reScheduled = true
      }, 3000)
    }
  }
}
</script>

<style lang="scss" scoped>
.re-scheduling-mask {
  align-items: center;
  color: $theme-color-primary;
  display: flex;
  font-size: 20px;
  height: 100%;
  justify-content: center;
}

.current-simulation {
  padding: 24px;
  position: relative;

  .header {
    display: inline-block;
    margin: 0 0 12px;
  }

  .simulate-btn {
    position: absolute;
    right: 24px;
    top: 24px;
  }

  .KPI {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
    padding: 0 14px;
    position: relative;

    &__title {
      font-size: 18px;
      line-height: 22px;
      margin: 0 30px 0 0;

      &::before {
        background: #2ad2e2;
        content: '';
        height: 6px;
        left: 0%;
        position: absolute;
        top: 37.5%;
        width: 6px;
      }
    }

    &__info {
      color: var(--color-text-light);
      font-size: 14px;
      line-height: 18px;
      margin-right: 24px;

      &--item {
        &:not(:last-child) {
          margin-right: 24px;
        }
      }
    }
  }

  .empty-block {
    align-items: center;
    background: var(--color-bg-gray);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    height: 249px;
    justify-content: center;

    &__icon {
      margin-bottom: 16px;
    }

    &__text {
      color: var(--color-text-gray);
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 16px;
    }

    &__btn {
      width: 260px;
    }
  }
}
</style>
